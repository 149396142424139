
export default {
  props: {
    forChoseModal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: ""
    },
    visibleIcon: {
      type: Boolean,
      default: false
    }
  },
  computed:{
		marksCategory(){
			return this.$route.params.category && this.$route.params.category === 'used'?
			'used'
			:'used'
		}
	}
};
