
export default {
        data() {
                return {
                        currentlyYear: null,
                        lastYear: null,
                        cars: [
                                {
                                        link: '/used/sedan',
                                        title: 'Седан',
                                        image: require('~/assets/img/makes-upper/Frame-9.svg')
                                },
                                // {
                                //         link: '/used/coupe',
                                //         title: 'Купе',
                                //         image: require('~/assets/img/makes-upper/Frame-1.svg')
                                // },
                                {
                                        link: '/used/universal',
                                        title: 'Универсал',
                                        image: require('~/assets/img/makes-upper/Frame-2.svg')
                                },
                                {
                                        link: '/used/hatchback',
                                        title: 'Хэтчбек',
                                        image: require('~/assets/img/makes-upper/Frame-3.svg')
                                },
                                {
                                        link: '/used/allroad',
                                        title: 'Внедорожник',
                                        image: require('~/assets/img/makes-upper/Frame-4.svg')
                                },
                                {
                                        link: '/used/liftback',
                                        title: 'Лифтбэк',
                                        image: require('~/assets/img/makes-upper/Frame-5.svg')
                                },
                                // {
                                //         link: '/used?body_type_id_array=31',
                                //         title: 'Пикап',
                                //         image: require('~/assets/img/makes-upper/Frame-6.svg')
                                // },
                                {
                                        link: '/used?body_type_id_array=9',
                                        title: 'Компактвэн',
                                        image: require('~/assets/img/makes-upper/Frame-7.svg')
                                },
                                {
                                        link: '/used/minivan',
                                        title: 'Минивэн',
                                        image: require('~/assets/img/makes-upper/Frame-8.svg')
                                },

                        ],
                        initSlider: false
                }
        },
        mounted() {
                // let leftPadding = this.$refs.benefitsSlider.getBoundingClientRect().left
                // let width = this.$refs.benefitsSlider.getBoundingClientRect().width
                // let indexOfView = leftPadding * 2 / (width / 3) + 3
                new swiper.default('.swiper--options_upper.swiper', {
                        modules: [swiper.Navigation, swiper.Autoplay],
                        loop: false,
                        // autoplayDisableOnInteraction: true,
                        autoplay: false,
                        initialSlide: 0,
                        // centeredSlides: true,
                        watchSlidesProgress: true,
                        // centeredSlidesBounds:true,
                        // roundLengths: true,
                        // freeMode:true,
                        // loop:true,
                        // centerInsufficientSlides:true,
                        // slidesOffsetAfter: this.$refs.benefitsSlider.getBoundingClientRect().left,
                        // cssMode:true,
                        slidesPerView: 3.3,
                        spaceBetween: 35,
                        // slidesOffsetBefore: leftPadding,
                        // slidesOffsetAfter: leftPadding,
                        breakpoints: {
                                // when window width is >= 320px
                                480: {
                                        slidesPerView: 4.3
                                },

                                640: {
                                        slidesPerView: 5.3
                                },
                                900: {
                                        slidesPerView: 7.3
                                },
                                1000: {
                                        slidesPerView: 9
                                }
                        },
                        on: {
                                init: (value) => {
                                        this.initSlider = true
                                }
                        }
                });
        }
}
